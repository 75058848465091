import React, { useCallback, useMemo, } from 'react';
import './conversation.list.row.scss';
import { Checkbox, Timeline, } from 'antd';
import { ConversationStatusEnum, IPreviewConversation, ISharedConversationFilter, } from 'atlas-shared';
import { MailIcon, PhoneIcon, UserIcon, } from '@Assets/icons';
import { preventDefault, TranslateAndDisplayPhoneNumber, } from '@Utils';
import { useTags, } from '@Hooks';
import { TFunction, } from 'i18next';
import {
  ConversationTagsSqueeze,
  ConversationAgent,
  ConversationReplyIn,
  ConversationStatus,
  ConversationDisposition,
  ConversationId,
  ConversationWatchers,
  IconText,
  ConversationTitle,
  ConversationQueue,
  ChannelsIcon,
  ChannelIcon, ConversationTime,
} from '@Components';
import { IAuth, } from '@Store';

interface IProps {
  auth: IAuth;
  t: TFunction;
  checked?: Array<IPreviewConversation>;
  onCheck?: (checked: boolean) => void;
  conversation: IPreviewConversation;
  shortened: boolean;
  onClick: ()=> void;
  selected: boolean;
  selectedFilter?: ISharedConversationFilter;
}

export const ConversationListRow = React.memo((props: IProps) => {
  const { conversation, checked, onCheck, shortened, onClick, t, selected, auth, selectedFilter, } = props;
  const tags = useTags();
  const contactName = useMemo(() => [conversation.contact?.first_name, conversation.contact?.last_name, ].filter(Boolean).join(' '), [conversation.contact, ]);

  const rendered = {
    queue: useMemo(() => <ConversationQueue conversation={conversation} />, [conversation, ]),
    contact_name: useMemo(() => <div>
      {conversation.contact && contactName && <IconText
        icon={UserIcon}
        className={'icon-style'}
        iconProps={{ className: 'icon-props', size: 10, }}
        textProps={{ className: 'icon-text-props', }}
        text={[conversation.contact.first_name, conversation.contact.last_name, ].join(' ')}
      />}
    </div>, [conversation.contact, ]),
    contact_email_phone: useMemo(() => <div className='contact_email_phone'>
      {
        (!!conversation.contact?.emails?.length && <IconText icon={MailIcon} className={'icon-style'} iconProps={{ className:'icon-props', size: 10, }} textProps={{ className:'icon-text-props', }} text={conversation.contact.emails[0]}/>)
      || (!!conversation.contact?.phones?.length && <IconText icon={PhoneIcon} className={'icon-style'} iconProps={{ className:'icon-props', size: 10, }} textProps={{ className:'icon-text-props', }} text={<TranslateAndDisplayPhoneNumber t={t} phone_number={conversation.contact.phones[0]} />}/>)
      }
    </div>, [conversation.contact, ]),
    watchers: useMemo(() => <div className={'watch-container'}>
      <ConversationWatchers className={'row watch'} conversation_watchers={conversation.conversation_watchers} />
    </div>, [conversation.conversation_watchers, ]),
    reply_in: useMemo(() => <div className='reply' style={shortened ? { justifyContent: 'flex-end', } : undefined}>
      <ConversationReplyIn className={'reply-in'} icon={true} short={true} conversation={(conversation)}/>
    </div>, [conversation.response_time, conversation.resolution_time, conversation.status, shortened, ]),
    time: useMemo(() => <ConversationTime conversation={conversation} selectedFilter={selectedFilter} t={t} />, [conversation, t, ]),
    title: useMemo(() => <ConversationTitle conversation={conversation} shortened={shortened} t={t} />, [conversation, shortened, t, ]),
    id: useMemo(() => <ConversationId conversation={conversation} shortened={shortened}/>, [conversation, shortened, ]),
    status: useMemo(() => <ConversationStatus t={t} conversation={conversation} shortened={shortened}/>, [conversation, shortened, t, ]),
    agent: useMemo(() => <ConversationAgent auth={auth} conversation={conversation} shortened={shortened} t={t} to_me={false} />, [conversation, shortened, t, ]),
    disposition: useMemo(() => <ConversationDisposition t={t} conversation={conversation} />, [conversation, ]),
    tags: useMemo(() => <ConversationTagsSqueeze conversation={conversation} t={t} tags={tags} />, [conversation, shortened, t, tags, ]),
  };

  const isChecked = useCallback((conversation) => {
    return !!checked?.some(selected_conversation => selected_conversation.id === conversation.id);
  }, [checked, ]);

  return (
    <div className={`inbox-row ${shortened ? 'shortened' : 'full-width'}${conversation.queue_id && selectedFilter?.filters[0].additional_queues?.includes(conversation.queue_id) ? ' from-additional' : ''}${selected ? ' selected' : ''}${conversation.status === ConversationStatusEnum.Closed ? ' closed' : ''}${conversation.state_updated_at ? ' updated' : ''}`} onClick={onClick}>
      <div className='additional-marker' title={t('FROM_ADDITIONAL')}></div>
      { conversation.state_updated_at && conversation.state_updated_text && <div className='conversation-update-text'>{t(conversation.state_updated_text)}</div> }
      {
        checked && onCheck &&
            <div className={'inbox-row-col-1'}>
              <Checkbox className={'cb'} checked={isChecked(conversation)} onClick={preventDefault(() => onCheck(!isChecked(conversation)))} />
            </div>
      }

      <div className={'inbox-row-col-2'}>
        <Timeline className={'timeline'}>
          {conversation.channels.length > 0 && <Timeline.Item dot={<ChannelIcon channel={conversation.channels[0]} size={12} padding={0}/>}/>}
          {
            conversation.channels.length > 1 && <Timeline.Item dot={<ChannelsIcon channels={conversation.channels.slice(1, 99)} max={2} size={9} />}/>
          }
        </Timeline>
      </div>

      <div className={'inbox-row-col-3'}>
        <div className={'row row-1'}>
          <div className='col col-3'>{rendered.title}</div>
          {shortened && <div className='col col-1'>{shortened && rendered.time}</div>}
          {!shortened && <>
            <div className='col col-1'>{rendered.id}</div>
            <div className='col col-2'>{rendered.contact_email_phone}</div>
            <div className='col col-2'>{rendered.queue}</div>
            <div className='col col-1'>{rendered.watchers}</div>
            <div className='col col-1'>{rendered.reply_in}</div>
            <div className='col col-1'>{rendered.tags}</div>
            <div className='col col-1'>{rendered.time}</div>
          </>}
        </div>

        <div className={'row row-2'}>
          {!shortened && <>
            <div className='col col-3'>{rendered.status}</div>
            <div className='col col-1'></div>
            <div className='col col-2'>{rendered.contact_name}</div>
            <div className='col col-2'>{rendered.agent}</div>
            <div className='col col-1'></div>
            <div className='col col-1'></div>
            <div className='col col-1'></div>
            <div className='col col-1'>{<ConversationDisposition t={t} conversation={conversation} />}</div>
          </>}
          {shortened && <>
            <div className='col col-2'>{rendered.id}</div>
            <div className='col col-2'>{rendered.queue}</div>
            <div className='col col-2'>{rendered.agent}</div>
          </>}
        </div>

        {shortened && <div className={'row row-3'}>
          <div className='col col-2'>
            {rendered.status}
            {rendered.tags}
            {<ConversationDisposition t={t} conversation={conversation} />}
          </div>
          <div className='col col-2'>{rendered.watchers}</div>
          <div className='col col-2'>{rendered.reply_in}</div>
        </div>}
      </div>

    </div>
  );
}, (prev, curr) => {
  return ['shortened', 'selected', 'checked', ].every(key => prev[key] === curr[key])
  && prev.conversation.conversation_watchers?.length === curr.conversation.conversation_watchers?.length
  && ['updated_at', 'state_updated_at', ].every(key => (prev.conversation[key] + '') === (curr.conversation[key] + ''));
});
