import React, { useCallback, useEffect, useState, } from 'react';
import { Icon, Stats, } from '@Components';
import { actionSetHideStats, IAuth, useAppDispatch, } from '@Store';
import './aside.scss';
import { AiAssistant, } from '@Components/ai-assistant';
import { CpuIcon, TrendingUpIcon, } from '@Assets';
import { useTranslation, } from 'react-i18next';
import { useHideStats, } from '@Hooks';
import { useLocation, } from 'react-router-dom';

interface IProps {
  auth: IAuth;
}

export const Aside = React.memo(({ auth, }: IProps) => {

  const { t, } = useTranslation();
  const [current, setCurrent, ] = useState(auth.user.dashboard_license ? 0 : auth.ai_assistant ? 1 : 0);
  const isHidden = useHideStats();

  const location = useLocation();
  const dispatch = useAppDispatch();

  const checkStatsStatus = useCallback((location) => {

    dispatch(actionSetHideStats([
      new RegExp(/\/stats/),
      new RegExp(/\/admin\/journey_draft\/\d{1,}\/edit/),
      new RegExp(/\/admin\/journey\/\d{1,}\/edit/),
    ].some(re => !!location.pathname.match(re)?.length)));

  }, [dispatch, ]);

  useEffect(() => {
    checkStatsStatus(location);
  }, [location, checkStatsStatus, ]);

  if (isHidden || !!auth?.user?.settings?.hide_stats_preview)
    return <></>;

  return <aside>
    {current === 0 && auth.user.dashboard_license && <Stats />}
    {current === 1 && auth.ai_assistant && <AiAssistant auth={auth} />}
    {current === 0 && auth.ai_assistant && <div className='aisde-switch' onClick={() => setCurrent(1)}>
      <Icon icon={CpuIcon} tooltip={{ title: t('SWITCH_TO_AI_ASSISTANT'), placement: 'left', }} />
    </div>}
    {current === 1 && auth.user.dashboard_license && <div className='aisde-switch' onClick={() => setCurrent(0)}>
      <Icon icon={TrendingUpIcon} tooltip={{ title: t('SWITCH_TO_STATS'), placement: 'left', }} />
    </div>}
  </aside>;
});
