import { FormElementTypes, FormLayoutTypes, IForm, IFormOptions, } from 'atlas-form';
import {
  AiAssistantTypeEnum,
  Countries,
  IPreviewAiAssistant,
  IPreviewPhonenumberMonthlyCost,
  IPreviewPhonenumberSetupCost,
  IPreviewTariff,
  ISharedCreateOrganization,
  ISharedOrganization,
  RoleEnum,
  TimezoneMapOptions,
} from 'atlas-shared';
import { TFunction, } from 'i18next';
import {
  IOrganizationFormOptionsProps,
  OrganizationAdditionalParams as OrganizationAdditionalParamsBase,
  OrganizationFormOptions as OrganizationFormOptionsBase,
} from './abstract/organization.form.abstract';
import { IAuth, } from '@Store';
import { entitiesToOptions, } from '@Utils/options';
import { filterRelated, } from '@Utils/relation';

export * from './abstract/organization.form.abstract';

export const OrganizationAdditionalParams: (auth: IAuth, props: IOrganizationFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IOrganizationFormOptionsProps) => ({
  ...OrganizationAdditionalParamsBase(auth, props),
  organization_id: { label: 'PARENT', ui_type: FormElementTypes.TREE, nullable: false, },
  two_factor_auth: { help: 'ORGANIZATION_2FA_HELP', },
  invoice_data: { hideLabel: true, },
  invoice_data_cin: { label: 'CUSTOMER_IDENTIFICATION_NUMBER', },
  invoice_data_VAT: { postFixText: '%', },
  invoice_data_company_emails: { ui_type: FormElementTypes.LIST, },
  invoice_dashboard_license_type: organization => ({ hidden: !organization.invoice?.owner, }),
  invoice_reporting_license_type: organization => ({ hidden: !organization.invoice?.owner, }),
  invoice_addons: { labelWidth: '160px', },

  invoice_addons_invoice_post_addon_key: { hidden: true, },
  invoice_addons_invoice_post_addon_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_addon_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_addon_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_all_license_key: { hidden: true, },
  invoice_addons_invoice_post_all_license_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_all_license_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_all_license_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_email_license_key: { hidden: true, },
  invoice_addons_invoice_post_email_license_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_email_license_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_email_license_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_email_usage_key: { hidden: true, },
  invoice_addons_invoice_post_email_usage_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_email_usage_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_email_usage_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_form_usage_key: { hidden: true, },
  invoice_addons_invoice_post_form_usage_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_form_usage_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_form_usage_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_license_key: { hidden: true, },
  invoice_addons_invoice_post_license_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_license_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_license_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_usage_key: { hidden: true, },
  invoice_addons_invoice_post_usage_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_usage_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_usage_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_license_key: { hidden: true, },
  invoice_addons_invoice_post_voice_license_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_license_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_license_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_numbers_monthly_key: { hidden: true, },
  invoice_addons_invoice_post_voice_numbers_monthly_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_numbers_monthly_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_numbers_monthly_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_numbers_setup_key: { hidden: true, },
  invoice_addons_invoice_post_voice_numbers_setup_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_numbers_setup_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_numbers_setup_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_tariff_key: { hidden: true, },
  invoice_addons_invoice_post_voice_tariff_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_tariff_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_tariff_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_usage_key: { hidden: true, },
  invoice_addons_invoice_post_voice_usage_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_usage_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_usage_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_recording_key: { hidden: true, },
  invoice_addons_invoice_post_voice_recording_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_recording_storage_key: { hidden: true, },
  invoice_addons_invoice_post_voice_recording_storage_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_storage_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_storage_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_voice_recording_transcript_key: { hidden: true, },
  invoice_addons_invoice_post_voice_recording_transcript_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_transcript_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_voice_recording_transcript_amount_currency: { hideLabel: true, },

  invoice_addons_invoice_post_ai_request_kb_assistant_key: { hidden: true, },
  invoice_addons_invoice_post_ai_request_kb_assistant_amount_type: { hideLabel: true, },
  invoice_addons_invoice_post_ai_request_kb_assistant_amount_percent: { postFixText: '%', hideLabel: true, },
  invoice_addons_invoice_post_ai_request_kb_assistant_amount_currency: { hideLabel: true, },

  invoice_license_cost_admin: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', },
  invoice_license_cost_admin_prepay: { labelWidth: '40px', },
  invoice_license_cost_admin_cost: { labelWidth: '30px', },
  invoice_license_cost_admin_surcharge: { postFixText: '%', labelWidth: '60px', },
  invoice_license_cost_all: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', label: 'ALL_CHANNELS', },
  invoice_license_cost_all_prepay: { labelWidth: '40px', },
  invoice_license_cost_all_cost: { labelWidth: '30px', },
  invoice_license_cost_all_surcharge: { postFixText: '%', labelWidth: '60px', },
  invoice_license_cost_voice: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', },
  invoice_license_cost_voice_cost: { labelWidth: '30px', },
  invoice_license_cost_voice_prepay: { labelWidth: '40px', },
  invoice_license_cost_voice_surcharge: { postFixText: '%', labelWidth: '60px', },
  invoice_license_cost_email: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', },
  invoice_license_cost_email_cost: { labelWidth: '30px', },
  invoice_license_cost_email_prepay: { labelWidth: '40px', },
  invoice_license_cost_email_surcharge: { postFixText: '%', labelWidth: '60px', },
  invoice_license_cost_dashboard: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', },
  invoice_license_cost_dashboard_type: { labelWidth: '40px', },
  invoice_license_cost_dashboard_cost: { labelWidth: '30px', },
  invoice_license_cost_dashboard_prepay: { labelWidth: '40px', },
  invoice_license_cost_dashboard_surcharge: { postFixText: '%', labelWidth: '60px', },
  invoice_license_cost_reporting: { ui_layout: FormLayoutTypes.INLINE, labelWidth: '70px', },
  invoice_license_cost_reporting_prepay: { labelWidth: '40px', },
  invoice_license_cost_reporting_type: { labelWidth: '40px', },
  invoice_license_cost_reporting_cost: { labelWidth: '30px', },
  invoice_license_cost_reporting_surcharge: { postFixText: '%', labelWidth: '60px', },

});

export const OrganizationFormOptions = (organization: ISharedCreateOrganization | ISharedOrganization, props: IOrganizationFormOptionsProps, t: TFunction): IFormOptions => ({
  ...OrganizationFormOptionsBase(organization, props, t),
  two_factor_auth: [
    { key: RoleEnum.Agent, title: t('AGENT'), },
    { key: RoleEnum.Admin, title: t('ADMIN'), },
  ],
  timezone: TimezoneMapOptions,
  invoice_data_company_address_country: Countries.map(([alpha2, ]) => ({
    key: alpha2,
    title: t(`COUNTRY_${alpha2}`),
  })),
  ai_assistant_id: (it: ISharedOrganization) => filterRelated<IPreviewAiAssistant>(it.id || (organization as ISharedOrganization).id, 'ai_assistant', 'organization', props.ai_assistants, true, it => it.type === AiAssistantTypeEnum.OrganizationAssistant),
  invoice_tariff_id: (_organization: ISharedCreateOrganization) => entitiesToOptions<IPreviewTariff>(props.tariffs, 'tariff', tariff => tariff.currency === _organization.invoice?.currency),
  invoice_phonenumber_monthly_cost_id: (_organization: ISharedCreateOrganization) => entitiesToOptions<IPreviewPhonenumberMonthlyCost>(props.phonenumber_monthly_costs, 'phonenumber_monthly_cost', tariff => tariff.currency === _organization.invoice?.currency),
  invoice_phonenumber_setup_cost_id: (_organization: ISharedCreateOrganization) => entitiesToOptions<IPreviewPhonenumberSetupCost>(props.phonenumber_setup_costs, 'phonenumber_setup_cost', tariff => tariff.currency === _organization.invoice?.currency),
});

export const OrganizationAddInitialValues: ISharedCreateOrganization = {
  organization_id: 1,
} as ISharedCreateOrganization;
