import React from 'react';
import './ai-assistant.scss';
import { IAuth, } from '@Store';

interface IProps {
  auth: IAuth;
}

export const AiAssistant = React.memo(({ auth, }: IProps) => {

  return <div id='ai-assistant'>
    <iframe src={`https://jollyroom.kb.connectel.app/widget/faq/${auth.ai_assistant}`}></iframe>
  </div>;

});
