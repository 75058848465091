import { TFunction, } from 'i18next';
import { timeSince, } from '@Utils';
import React, { useCallback, useEffect, useState, } from 'react';
import { ConversationFilterSortColumnEnum, IPreviewConversation, ISharedConversationFilter, } from 'atlas-shared';

interface IProps {
  t: TFunction;
  conversation: IPreviewConversation;
  selectedFilter?: ISharedConversationFilter;
}

export const ConversationTime = React.memo(({ conversation, selectedFilter, t, }: IProps) => {
  const renderTime = useCallback(() => {

    let timestamp = conversation.created_at;

    switch (selectedFilter?.sort_by?.column) {
    case ConversationFilterSortColumnEnum.StatusAt:
      if (conversation.status_at)
        timestamp = conversation.status_at;

      break;

    case ConversationFilterSortColumnEnum.LastMessageAt:
      if (conversation.status_at)
        timestamp = conversation.last_message_at;

      break;

    case ConversationFilterSortColumnEnum.LastInboundMessageAt:
      if (conversation.status_at)
        timestamp = conversation.last_inbound_message_at;

      break;

    case ConversationFilterSortColumnEnum.LastOutboundMessageAt:
      if (conversation.status_at)
        timestamp = conversation.last_outbound_message_at;

      break;

    case ConversationFilterSortColumnEnum.ResolutionTime:
      if (conversation.resolution_time)
        timestamp = conversation.resolution_time;

      break;

    case ConversationFilterSortColumnEnum.ResponseTime:
      if (conversation.response_time)
        timestamp = conversation.response_time;

      break;

    case ConversationFilterSortColumnEnum.AssignedAt:
      if (conversation.assigned_at)
        timestamp = conversation.assigned_at;

      break;

    }
    return timeSince(t, new Date(timestamp));
  }, [t, conversation.created_at, selectedFilter?.sort_by, ]);

  const [time, setTime, ] = useState<string>(renderTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(renderTime());
      return () => clearInterval(timer);
    }, 60_000);
  }, [conversation.created_at, renderTime, ]);

  return (
    <small className='time'>
      {time}
    </small>
  );
});
