import './conversation.message.form_submission.scss';
import React, { ReactElement, useEffect, useState, } from 'react';
import { ISharedFormSubmission, ISharedFullConversation, ISharedFormSubmissionAttachmentProperty, IPreviewForm, IPreviewField, FormElementTypeExposedEnum, IPreviewFormWebsite, } from 'atlas-shared';
import { timeSince, TranslateAndDisplayPhoneNumber, } from '@Utils';
import { ConversationMessageReplies, ConversationMessageReply, } from '@Components/conversation';
import { THighlightedMessage, useFields, useForms, useFormWebsites, } from '@Hooks';

import { TFunction, } from 'i18next';
import { IAuth, } from '@Store';
import { ConversationMessageFormSubmissionAttachment, } from './conversation.message.form_submission.attachment';

interface IProps {
  t: TFunction;
  dispatch: any;
  auth: IAuth;
  message: ISharedFormSubmission;
  conversation: ISharedFullConversation;
  highlightedMessage?: THighlightedMessage;
  isStatic?: boolean;
}

export const ConversationMessageFormSubmission = React.memo(({
  t,
  auth,
  message,
  conversation,
  highlightedMessage,
  dispatch,
  isStatic = false,
}: IProps) => {

  const forms = useForms();
  const form_websites = useFormWebsites();
  const fields = useFields();
  const [table, setTable, ] = useState<Array<{title: string | ReactElement; value: string | ReactElement}>>([]);
  const [formWebsite, setFormWebsite, ] = useState<IPreviewFormWebsite>();
  const [form, setForm, ] = useState<IPreviewForm>();
  const [displayFields, setDisplayFields, ] = useState<Array<IPreviewField>>([]);

  useEffect(() => {
    if (form_websites.loaded && message.form_website_id)
      setFormWebsite(form_websites.dict[message.form_website_id]);
  }, [form_websites.loaded, message.form_website_id, ]);

  useEffect(() => {
    if (forms.loaded && formWebsite?.form_id)
      setForm(forms.dict[formWebsite.form_id]);
  }, [forms.loaded, formWebsite?.id, ]);

  useEffect(() => {
    if (form && fields.fields.length)
      setDisplayFields(
        form.__submission_display_fields
          .map(fid => fields.dict[fid])
          .filter(field => !(isStatic && field.settings.ui_type === FormElementTypeExposedEnum.FILE) )
      );
  }, [fields.loaded, form, ]);

  useEffect(() => {
    setTable((displayFields || []).flatMap(field => {
      const attachment_index = (message.attachments || []).findIndex(attachment => attachment.field_name === field.alias);

      const response = [
        {
          title: <span>{field.display_title || field.title}</span>,
          value: field.settings.ui_type === FormElementTypeExposedEnum.FILE
            ? message.attachments && !!~attachment_index ? <ConversationMessageFormSubmissionAttachment
              dispatch={dispatch}
              key={attachment_index}
              organization_id={message.organization_id}
              attachments={message.attachments as Array<ISharedFormSubmissionAttachmentProperty>}
              index={attachment_index}
              attachment={message.attachments[attachment_index]}
              form_submission_id={message.id}
              t={t}
              anonymized={!!message.anonymized_at}
            /> : ''
            :
            field.alias === '__contact_phones'
              ? ((message.data?.[field.alias] as Array<string> || [])).map(phone => <TranslateAndDisplayPhoneNumber t={t} phone_number={phone} />)
              : field.settings.ui_type === FormElementTypeExposedEnum.LIST
                ? (message.data[field.alias] || []).join(', ')
                : message.data[field.alias] || '',
        },
      ];

      field.settings?.validation?.valid?.forEach(option => {
        if (option.custom_answer) {
          response.push({
            title: <span>{field.title}</span>,
            value: message.data[`${field.alias}___${option.alias}`],
          });
        }
      });

      return response;
    }));
  }, [displayFields, message.data, ]);

  return (
    <div className={!isStatic ? 'conversation-message conversation-message-form-submission inbound' : undefined}>
      {!isStatic && <ConversationMessageReply t={t} auth={auth} message={message} conversation={conversation}/>}
      <div className={!isStatic ? 'conversation-message-content' : undefined}>
        <span className={'head'}>
          <strong>{timeSince(t, new Date(message.created_at), true)}</strong>
        </span>
        {
          !isStatic && message.attachments && <div className={'attachments'} >
            {message.attachments.map((attachment, index) => <ConversationMessageFormSubmissionAttachment
              dispatch={dispatch}
              key={index}
              organization_id={message.organization_id}
              attachments={message.attachments as Array<ISharedFormSubmissionAttachmentProperty>}
              index={index}
              attachment={attachment}
              form_submission_id={message.id}
              t={t}
              anonymized={!!message.anonymized_at}
            />)}
          </div>
        }
        {message.anonymized_at && <div className="anonymized_body">{t('ANONYMIZED')}</div>}
        {form && <table className='data' style={{ width: '100%', }}>
          {table.map(({ title, value, }) => {
            return <tr>
              <td className='key'>{title}</td>
              <td className='value'>{value}</td>
            </tr>;
          })}
        </table>}
        {!message.anonymized_at && message.body && <p className={!isStatic ? 'body' : undefined} dangerouslySetInnerHTML={{ __html: message.body || `<div class="missing_body">${t('FORM_SUBMISSION_HAS_NO_BODY')}</div>`, }}/>}
      </div>
      {!isStatic && <ConversationMessageReplies
        message={message}
        conversation={conversation}
        highlightedMessage={highlightedMessage}
      />}
    </div>
  );
});
