import { RestRequest, } from '@Utils';
import { IPreviewVoiceRoute, ISharedVoiceRoute, ISharedCreateVoiceRoute, ISharedPatchVoiceRoute, } from 'atlas-shared';

export const fetchVoiceRoutes = async (): Promise<Array<IPreviewVoiceRoute>> => {
  return RestRequest.get<Array<IPreviewVoiceRoute>>('voice_route', { socket_id: global.socketId, });
};

export const fetchVoiceRoute = async (id: IPreviewVoiceRoute['id']): Promise<ISharedVoiceRoute> => {
  return RestRequest.get<ISharedVoiceRoute>(`voice_route/${id}`, { socket_id: global.socketId, });
};

export const createVoiceRoute = (payload: ISharedCreateVoiceRoute) => {
  return RestRequest.post<any, ISharedVoiceRoute>('voice_route', payload, { socket_id: global.socketId, });
};

export const patchVoiceRoute = (id: IPreviewVoiceRoute['id'], payload: ISharedPatchVoiceRoute) => {
  return RestRequest.patch<any, ISharedVoiceRoute>(`voice_route/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteVoiceRoute = (id: IPreviewVoiceRoute['id']) => {
  return RestRequest.delete(`voice_route/${id}`, { socket_id: global.socketId, });
};

export const getVoiceRouteUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_route/update/schema');
};

export const getVoiceRouteCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_route/create/schema');
};

export const getVoiceRoutePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_route/patch/schema');
};
