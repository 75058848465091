import { IAuthResponse, IPreviewOrganization, isSuperAdmin, Nullable, } from 'atlas-shared';

export const UserAiAssistant = (organizations: Array<IPreviewOrganization>, user: IAuthResponse['user']): Nullable<IPreviewOrganization['ai_assistant_key']> => {

  if (isSuperAdmin(user))
    return null;

  const organization_id = [...user.__admin_organization_ids, ...user.__agent_organization_ids, ].find(oid => organizations.find(o => o.id === oid)?.ai_assistant_key);

  if (!organization_id)
    return null;

  return organizations.find(o => o.id === organization_id)?.ai_assistant_key || null;
};
