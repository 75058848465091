import { useTranslation, } from 'react-i18next';
import { Badge, Button, Popover, } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState, } from 'react';
import {
  useAgentprofiles,
  useClients,
  useDispositions,
  useOrganizations,
  useQueues,
  useSkills,
  useTags,
  useUsers,
} from '@Hooks';
import { createConversationFilter, deleteConversationFilter, patchConversationFilter, patchUserStatus, } from '@Api';
import {
  ConversationFlagEnum,
  ConversationPriorityEnum,
  ConversationStatusEnum,
  EnumToArray,
  IPreviewAgentprofile,
  IPreviewConversationFilter,
  ISharedConversationFilter,
  ISharedPatchConversationFilter,
  ISharedUserStatus,
  Undefinable,
  ChannelEnum,
  ISharedCreateConversationFilter,
  DirectionEnum,
  ISharedQueue, ISharedPatchConversationFilterFilterProperty,
} from 'atlas-shared';
import { AtlasForm, IForm, } from 'atlas-form';
import {
  clone,
  entitiesToOptions,
  entitiesToOptionsWithOrganization,
  filterRelated,
  isAgent,
  isSuperAdmin,
  uniq,
} from '@Utils';
import { CheckIcon, FilterIcon, ListIcon, TrashIcon, } from '@Assets/icons';
import { IAuth, } from '@Store';
import Joi from 'joi';
import { Icon, IconText, } from '@Components';

interface IProps {
  auth: IAuth;
  selected: Undefinable<ISharedConversationFilter>;
  default_filters: Array<ISharedCreateConversationFilter>;
  chosenFilters: number;
  conversation_filters: Array<IPreviewConversationFilter>;
  schema: Undefinable<Joi.Schema>;
  shortened: boolean;
}
export const ConversationFilter = React.memo(({ selected, chosenFilters, conversation_filters, auth, schema, shortened, default_filters, }: IProps) => {

  const { t, } = useTranslation();
  const [selectFilterVisible, setSelectFilterVisible, ] = useState<boolean>(false);
  const [editFilterVisible, setEditFilterVisible, ] = useState<boolean>(false);
  const switchConversationFilter = (conversation_filter_id: ISharedUserStatus['conversation_filter_id']) => patchUserStatus(auth.user_status.id, { conversation_filter_id, });
  const [form, setForm, ] = useState<ReactElement>();
  const [agentprofile, setAgentprofile, ] = useState<IPreviewAgentprofile>();
  const [agentAgentprofiles, setAgentAgentprofiles, ] = useState<Array<IPreviewAgentprofile>>();
  const [allProfileQueues, setAllProfileQueues, ] = useState<Array<ISharedQueue['id']>>([]);

  const organizations = useOrganizations();
  const agentprofiles = useAgentprofiles();
  const queues = useQueues();
  const clients = useClients();
  const skills = useSkills();
  const tags = useTags();
  const dispositions = useDispositions();
  const users = useUsers();

  useEffect(() => {
    setAgentAgentprofiles(agentprofiles.agentprofiles.filter(agentprofile => auth.user.__agentprofiles.includes(agentprofile.id)));
  }, [auth.user.__agentprofiles, agentprofiles, ]);

  useEffect(() => {
    setAllProfileQueues(uniq(agentAgentprofiles?.map(agentAgentprofile => agentAgentprofile.__queues).flat() || []));
  }, [agentAgentprofiles, ]);

  useEffect(() => {
    if (!auth.user_status?.agentprofile_id || !agentprofiles.loaded)
      return;

    setAgentprofile(agentprofiles.dict[auth.user_status.agentprofile_id]);
  }, [agentprofiles, auth.user_status.agentprofile_id, ]);

  const [filter, setFilter, ] = useState<ISharedPatchConversationFilterFilterProperty>({});

  const organization_ids = useMemo(() => {
    return agentprofile && !filter?.additional_queues?.length ? [agentprofile.organization_id, ].filter(Boolean) : [...auth.user.__agent_organization_ids, ...auth.user.__admin_organization_ids, ];
  }, [filter, agentprofile, ]);

  const organizations_store = useMemo(() => {
    return !agentprofile && organization_ids.length && filter.organizations?.length !== 1 ? organizations : null;
  }, [filter, agentprofile, organization_ids, ]);

  const organization_options = useMemo(() => {
    return entitiesToOptions(organizations.organizations, 'organization');
  }, [organizations, ]);

  const tag_options = useMemo(() => {
    return (entitiesToOptionsWithOrganization(organizations_store, tags.tags, 'tag', tag => {
      return (!agentprofile || tag.organization_id === agentprofile.organization_id) && (!filter.organizations?.length || filter.organizations.includes(tag.organization_id));
    }));
  }, [agentprofile, filter, organizations_store, tags, ]);

  const disposition_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_DISPOSITION'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, dispositions.dispositions, 'disposition', disposition => {
        return !(disposition.parents || []).length && (!agentprofile || disposition.organization_id === agentprofile.organization_id) && (!filter.organizations?.length || filter.organizations.includes(disposition.organization_id));
      })),
    ];
  }, [dispositions, organizations_store, filter, ]);

  const sub_disposition_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_DISPOSITION'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, dispositions.dispositions, 'disposition', disposition => {
        return (disposition.parents || []).length >= 1
          && (
            !agentprofile ||
            disposition.organization_id === agentprofile.organization_id
          )
          && (
            !filter.organizations?.length
            || filter.organizations.includes(disposition.organization_id)
          )
          && (
            !filter.dispositions?.length
            || filter.dispositions.some(did => disposition.parents!.includes(did))
          );
      })),
    ];
  }, [dispositions, organizations_store, filter, ]);

  const thrd_disposition_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_DISPOSITION'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, dispositions.dispositions, 'disposition', disposition => {
        return (disposition.parents || []).length >= 1
          && (
            !agentprofile
            || disposition.organization_id === agentprofile.organization_id
          )
          && (
            !filter.organizations?.length
            || filter.organizations.includes(disposition.organization_id)
          )
          && (
            !filter.sub_dispositions?.length
            || filter.sub_dispositions.some(did => disposition.parents!.includes(did))
          );
      })),
    ];
  }, [dispositions, organizations_store, filter, ]);

  const skill_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_SKILL'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, skills.skills, 'skill', skill => {
        return (!agentprofile || skill.organization_id === agentprofile.organization_id) && (!filter.organizations?.length || filter.organizations.includes(skill.organization_id));
      })),
    ];
  }, [skills, organizations_store, filter, ]);

  const client_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_CLIENT'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, clients.clients, 'client', client => {
        return (!agentprofile || client.organization_id === agentprofile.organization_id) && (!filter.organizations?.length || filter.organizations.includes(client.organization_id));
      })),
    ];
  }, [clients, organizations_store, filter, ]);

  const queue_options = useMemo(() => {
    return [
      { key: 0, title: t('NO_QUEUE'), },
      ...(entitiesToOptionsWithOrganization(organizations_store, queues.queues, 'queue', queue => {
        return (!agentprofile || agentprofile.__queues.includes(queue.id)) && (!filter.organizations?.length || filter.organizations.includes(queue.organization_id));
      })),
    ];
  }, [queues, organizations_store, filter, ]);

  const additional_queues_options = useMemo(() => {
    return [
      ...(entitiesToOptionsWithOrganization(organizations_store, queues.queues, 'queue', queue => {
        return !(agentprofile?.__queues || []).includes(queue.id);
      })).map(option => ({
        ...option,
        deleted: !allProfileQueues.includes(option.key as number),
      })),
    ];
  }, [queues, allProfileQueues, organizations_store, filter, ]);

  const user_options = useMemo(() => {
    return [{ key: 0, title: t('UNASSIGNED'), }, ...(agentprofile ? filterRelated(agentprofile.organization_id, 'user_reverse', 'conversation_filter', users.users.filter(user => isAgent(user)), true) : entitiesToOptions(users.users, 'user', it => isAgent(it))), ];
  }, [agentprofile, users, ]);

  const form_options = useMemo(() => {
    return {
      filters_organizations: organization_options,
      filters_tags: tag_options,
      filters_auto_tags: tag_options,
      filters_priorities: EnumToArray(ConversationPriorityEnum).map(([v, k, ]) => ({ key: v, title: t(`PRIORITY_${k.toUpperCase()}`), })),
      filters_statuses: EnumToArray(ConversationStatusEnum).map(([v, k, ]) => ({ key: v, title: t(k.toUpperCase()), })),
      filters_flags: [{ key: 0, title: t('NO_FLAG'), }, ...EnumToArray(ConversationFlagEnum).map(([v, k, ]) => ({ key: v, title: t(k.toUpperCase()), })), ],
      filters_directions: [DirectionEnum.InBound, DirectionEnum.Outbound, ].map(key => ({ key, title: t(`DIRECTION_${key.toUpperCase()}`), })),
      filters_channels: [ChannelEnum.Mail, ChannelEnum.Form, ChannelEnum.Voice, ].map(key => ({ key, title: t(`CHANNEL_${key.toUpperCase()}`), })),
      filters_dispositions: disposition_options,
      filters_sub_dispositions: sub_disposition_options,
      filters_thrd_dispositions: thrd_disposition_options,
      filters_queues: queue_options,
      filters_additional_queues: additional_queues_options,
      filters_skills: skill_options,
      filters_clients: client_options,
      filters_users: user_options,
    };
  }, [
    organization_options,
    tag_options,
    disposition_options,
    sub_disposition_options,
    thrd_disposition_options,
    queue_options,
    additional_queues_options,
    skill_options,
    client_options,
    user_options,
  ]);
  const formLayout: IForm = useMemo(() => {
    const layout = {
      id: 'filter_form',
      compact: true,
      elements: [
        { ref: 'title', },
        {
          ref: 'filters.0',
          hideLabel: true,
          noPadding: true,
          compact: true,
          elements: [
            { ref: 'queues', },
            { ref: 'additional_queues', },
            { ref: 'skills', },
            { ref: 'users', },
            { ref: 'statuses', },
            { ref: 'flags', },
            { ref: 'channels', },
            { ref: 'directions', },
            { ref: 'priorities', },
            { ref: 'clients', },
            { ref: 'dispositions', },
            { ref: 'sub_dispositions', },
            { ref: 'thrd_dispositions', },
            { ref: 'tags', },
            { ref: 'auto_tags', },
            { ref: 'contacts', },
            { ref: 'created_at', },
            { ref: 'sla_response', },
            { ref: 'sla_resolution', },
            { ref: 'is_waiting', },
            { ref: 'is_ongoing', },
          ],
        },
      ],
    };

    if (isSuperAdmin(auth.user) || (selected?.filters[0].organizations?.length || 0) > 0)
      layout.elements[1].elements = [
        { ref: 'organizations', },
        // @ts-ignore
        ...layout.elements[1].elements,
      ];

    return layout;
  }, [auth, selected, ]);

  const filtersCallback = useCallback((_f: ISharedPatchConversationFilter) => {

    const f: ISharedPatchConversationFilterFilterProperty = _f.filters?.[0] || ({} as ISharedPatchConversationFilterFilterProperty);

    if (JSON.stringify(f) !== JSON.stringify(filter)) {
      setFilter(f);
    }

    return { compact: true, noPadding: true, labelWidth: '120px', };
  }, [filter, ]);

  const initialValues = useMemo(() => {
    return selected ? {
      title: selected.title,
      filters: selected.filters,
    } : {};
  }, [selected, ]);

  useEffect(() => {
    if (!schema || !selected)
      return;

    setForm(<div className='conversation-filter-popover-form' >
      <AtlasForm
        // key={`form-${Math.random()}`}
        form={schema}
        formLayout={formLayout}
        initialValues={initialValues}
        additionalParams={{
          filters: filtersCallback,
          filters_contacts: { hidden: true, },
          title: { hideLabel: true, },
          filters_additional_queues: {
            help: 'HELP_ADDITIONAL_QUEUES',
            hideHelp: true,
            // hidden: !!additional_queues_options.length,
          },
          filters_created_at: {
            label: 'CREATED_AFTER',
          },
          filters_is_waiting: {
            help: 'HELP_CONVERSATION_FILTER_IS_WAITING',
            hideHelp: true,
          },
          filters_is_ongoing: {
            help: 'HELP_CONVERSATION_FILTER_IS_ONGOING',
            hideHelp: true,
          },
        }}
        options={form_options}
        onFinish={(values: ISharedPatchConversationFilter) => {
          setEditFilterVisible(false);
          return selected.id > 0 ? patchConversationFilter(selected.id, values) : createNewConversationFilter(selected.id, values);
        }}
      />
    </div>);
  }, [
    selected,
    schema,
    formLayout,
    form_options,
    filtersCallback,
    initialValues,
  ]);

  const createNewConversationFilter = useCallback(async (filter_id, values: ISharedPatchConversationFilter): Promise<IPreviewConversationFilter | undefined> => {
    // @ts-ignore
    const { id, created_at, updated_at, ..._filter } = filter_id < 0 ? default_filters.find(f => (f as any).id === filter_id) : conversation_filters.find(cf => cf.id === filter_id);

    const filter = clone(_filter);

    if (values?.filters?.[0])
      filter.filters = values.filters;

    if (filter) {
      const conversation_filter = await createConversationFilter({ ...filter, title: `${filter.title} (${id > 0 ? t('CLONE') : t('CUSTOM')})`, });

      if (conversation_filter)
        switchConversationFilter(conversation_filter.id);

      return conversation_filter;
    }

    return undefined;
  }, [auth, default_filters, ]);

  if (!schema)
    return <></>;

  const style: React.CSSProperties = {};

  if (shortened)
    style.flexBasis = '100%';

  return <div className={'conversation-filter'} style={style} title={selected?.title}>

    <Popover
      content={<div className='conversation-filter-select-popover'>
        <ul>
          {!conversation_filters.length && <li>{t('NO_CUSTOM_FILTERS_CREATED')}</li>}
          {[...default_filters, ...conversation_filters, ].flatMap(_conversation_filter => {
            const conversation_filter = _conversation_filter as unknown as ISharedConversationFilter;
            const isSelected = conversation_filter.id === (selected?.id || -1);

            return <li key={`conversation_filter-${conversation_filter.id}`} value={conversation_filter.id} title={conversation_filter.title}>
              <Icon icon={CheckIcon} circle={true} className={isSelected ? 'selected' : ''} />
              <span className='text' onClick={e => switchConversationFilter(conversation_filter.id)}>{conversation_filter.title}</span>
              {conversation_filter.id > 0 && !isSelected && <Icon icon={TrashIcon} className={'delete'} onClick={() => deleteConversationFilter(conversation_filter.id)} />}
            </li>;
          })}

        </ul>
        {selected && <Button
          onClick={e => createNewConversationFilter(selected.id, {}).then(conversation_filter => conversation_filter && switchConversationFilter(conversation_filter.id))}
        >
          {t('CREATE_NEW_FILTER')}
        </Button>}
      </div>}
      title={t('MY_FILTERS')}
      trigger="click"
      open={selectFilterVisible}
      onOpenChange={visible => setSelectFilterVisible(visible)}
      placement='bottomRight'
      overlayStyle={{ minWidth: 'auto', }}
    >
      <IconText icon={ListIcon} text={selected?.title} className={'select-conversation-filter'} />
    </Popover>

    {
      /*
      selected && Object.entries(selected.filters[0])
        .filter(([key, section]) => {
          return section.length;
        })
        .map(([key, section]) => {
          return <div>{key}: {section}</div>;
        })
       */
    }
    {selected && form && <Popover
      content={form}
      title={selected.title}
      trigger="click"
      open={editFilterVisible}
      onOpenChange={visible => setEditFilterVisible(visible)}
      placement='bottomRight'
      key={`conversation_filter-popover-${selected?.id}`}
    >
      <Badge count={chosenFilters} showZero>
        <Icon icon={FilterIcon} iconProps={{ style: { color: 'var(--COLOR-FFFFFF)', }, }} className={'edit-conversation-filter'} />
      </Badge>
    </Popover>}

  </div>;

});
