import { Input, Checkbox, } from 'antd';
import { Icon, Button, } from '@Components';
import { SearchIcon, } from '@Assets/icons';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, } from 'react';
import { FilterDropdownProps, } from 'antd/es/table/interface';
import { TFunction, } from 'i18next';
import { getFullName, } from '@Utils';

export interface ITableDataFilter {
  [key: string]: {
    match: any;
  };
}

export type TypeTableDataFilterState = [ITableDataFilter, Dispatch<SetStateAction<ITableDataFilter>>];

export function filterTableData<T = any>(items: Array<T>, showDeleted: boolean, dataFilter: ITableDataFilter) {
  const entries = Object.entries(dataFilter);

  return !showDeleted || entries.length ? items.filter(p => {
    if (!showDeleted && (p as any).deleted_at)
      return false;

    for (const [key, val, ] of entries) {
      if (!(p[key] || '').toLowerCase().includes(val.match.toLowerCase()))
        return false;
    }
    return true;
  }) : items;

}

const FilterDropdown = ({ t, title, confirm, setSelectedKeys, selectedKeys, clearFilters, visible, options, }: FilterDropdownProps & {dataIndex: string; t: TFunction; title: string; options: Array<any>}) => {

  const sortedOptions = useMemo(() => {
    return [...options.filter(option => !option?.deleted_at), ].sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
  }, [options, ]);

  useEffect(() => {
    visible && setTimeout(() => (ref.current as any)?.focus(), 100);
  }, [visible, ]);

  const ref = useRef(null);

  return <div style={{ padding: 8, }}>
    {!sortedOptions?.length && <Input
      ref={ref}
      value={selectedKeys[0] as any}
      placeholder={`${t('SEARCH')} ${title}`}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value, ] : [])}
      onPressEnter={() => confirm()}
      style={{ marginBottom: 8, display: 'block', }}
    />}
    {sortedOptions.length > 0 && <div>
      {sortedOptions.map(option => {
        const key = option.key || option.id;

        return <div>
          <Checkbox
            checked={(((selectedKeys?.[0] as any) || [])).includes(key)}
            onChange={e => setSelectedKeys(e.target.checked
              ? [[...((selectedKeys?.[0] as any) || []), key, ], ]
              : [((selectedKeys?.[0] as any) || []).filter(k => k !== key), ] as any
            )}
          >
            {option?.title || ((option.first_name || option.last_name) && getFullName(option)) || '???'}
          </Checkbox>
        </div>;
      })}
    </div>}
    <div className='buttons'>
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<Icon icon={SearchIcon} iconProps={{ size: 14, style: { color: 'white', }, }}/>}
        size="small"
        style={{ width: 90, }}
      >
        {t('APPLY')}
      </Button>
      <Button onClick={() => {
        clearFilters?.();
        confirm();
      } } size="small" style={{ width: 90, }}>
        {t('RESET')}
      </Button>
    </div>
  </div>;
};

const nestedFilter = (value, record, dataIndex, equal: boolean = false) => {
  const keep = Array.isArray(value) ? value.includes(record[dataIndex]) : (
    equal
      ? (record[dataIndex] || '').toString().toLowerCase() === value.toLowerCase()
      : (record[dataIndex] || '').toString().toLowerCase().includes(value.toLowerCase())
  );

  if (!keep && record.children?.length)
    return record.children.some(child => nestedFilter(value, child, dataIndex));

  return keep;
};

export const GetColumnSearchProps = (t, dataIndex, title, options: Array<any> = []) => {

  return {
    filterDropdown: (props: FilterDropdownProps): any => {
      return <FilterDropdown { ...{ ...props, dataIndex, t, title, options, } } />;
    },
    filterIcon: filtered => <Icon icon={SearchIcon} iconProps={{ style: { opacity: filtered ? 1 : .25, }, }}/>,
    onFilter: (value, record) => nestedFilter(value, record, dataIndex),
  };

};

export const GetColumnSearchEqualProps = (t, dataIndex, title, options: Array<any> = []) => {

  return {
    ...GetColumnSearchProps(t, dataIndex, title, options),
    onFilter: (value, record) => nestedFilter(value, record, dataIndex, true),
  };

};

export const GetColumnSearchLikeProps = (t, dataIndex, title, options: Array<any> = []) => {

  return {
    ...GetColumnSearchProps(t, dataIndex, title, options),
    onFilter: (value, record) => nestedFilter(value, record, dataIndex),
  };

};
