import { IForm, IFormOptions, } from '@Modules/atlas-form-core';
import {
  ISoundFormOptionsProps,
  SoundComponents as SoundComponentsBase,
  SoundFormOptions as SoundFormOptionsBase,
} from './abstract/sound.form.abstract';
import { FormComponentElevenlabs, } from './components/elevenlabs';
import {
  ElevenlabsVoiceEnum,
  EnumOptionsKey,
  ISharedCreateSound,
  ISharedSound,
} from 'atlas-shared';
import { TFunction, } from 'i18next';

export * from './abstract/sound.form.abstract';

export const SoundComponents: IForm['components'] = {
  ...SoundComponentsBase,
  text: FormComponentElevenlabs,
};

export const SoundFormOptions = (sound: ISharedCreateSound | ISharedSound, props: ISoundFormOptionsProps, t: TFunction): IFormOptions => ({
  ...SoundFormOptionsBase(sound, props, t),
  elevenlabs_settings_voice: EnumOptionsKey('ELEVENLABS_VOICE', ElevenlabsVoiceEnum, t),
});
